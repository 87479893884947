import React from "react"
// import LogoWithText from "./LogoWithText"

function Footer() {
  return (
    <div
      className="bg-[#090513]"
      style={{
        backgroundImage: "url(/images/footer-bg.png)",
      }}
    >
      <div className="flex flex-col justify-between max-w-5xl px-4 py-20 mx-auto text-privacy md:px-0">
        <div className="flex items-center justify-between">
          <div className="flex flex-col">
            {/* <LogoWithText /> */}
            <img src="/logo.svg" className="w-[150px]" />
            {/* <p className="text-gray-300 mt-4">
              The Beast returns to SmackDown to unleash a vicious attack on
              Theory
            </p> */}
          </div>

          <div className="flex space-x-2 md:mt-8">
            <a href="" target="_blank">
              <img width="32px" src="/images/twitter.svg" />
            </a>
            <a href="" target="_blank">
              <img width="32px" src="/images/youtube.svg" />
            </a>
            <a href="" target="_blank">
              <img width="32px" src="/images/telegram.svg" />
            </a>
            <a href="" target="_blank">
              <img width="32px" src="/images/telegram.svg" />
            </a>
          </div>

        </div>

        {/* <div className="flex mt-10">
          <div className="flex flex-col">
            <p className="text-2xl font-bold text-white">PAGES</p>
          </div>

          <div className="mt-8">
            <p></p>
          </div>
        </div> */}
      </div>
    </div>
  )
}

export default Footer
