import React from "react"
import { Link } from "react-router-dom"

function Denomination() {
  return (
    <div className="my-52">
      <div
        className="mx-auto grid max-w-6xl grid-cols-1 md:grid-cols-3 gap-6 md:gap-0 px-4 md:px-0"
        style={{
          // gridTemplateColumns: "35% 65%",
        }}
      >
        <div className="flex-col self-center pr-16 col-span-1">
          <p className="text-4xl">Money denmomination made your day!</p>
          <p className="my-16">
            Which denominations of USD is hiden behind your mystery cards? Let
            your luck find it for you!
          </p>
          <div className="w-fit bg-[#31B5FF] p-1">
            <Link to="/safe">
              <button className="border border-solid border-[#0A0014] bg-[#31B5FF] px-16 py-3 font-bold text-black duration-300 hover:scale-105">
                BUY SAFE
              </button>
            </Link>
          </div>
        </div>
        <div className="col-span-2">
          <img src="/images/landingpage/denomination.png" alt="" />
        </div>
      </div>
    </div>
  )
}
export default Denomination
