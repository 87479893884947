import { roadmapData } from "./config"

function Roadmap() {
  console.log(roadmapData)
  return (
    <div className="relative">
      <img className="absolute w-[200px] md:w-[300px] -left-40 top-0" src="/images/ellipse.svg" alt="" style={{
                  zIndex: 999,
                }}/>

      <div className="mx-auto max-w-6xl flex-col px-4 md:px-0">
        <p className="text-center text-4xl">Our’s Roadmap</p>
        <p className="mx-auto mt-9 mb-28 max-w-xl text-center">
          We desire to build new payment instrument that meet all the strict
          requirement of users, so we drawn the clear and detailed roadmap below
        </p>
        {/* <img src="/images/roadmap/roadmap.png" alt="" /> */}
        <div className="relative mt-20 pb-20 grid grid-cols-12  gap-3 md:grid-cols-12">
          {roadmapData.map((item, index) =>
            index == 0 || index % 2 == 0 ? (
              <div
                className="col-span-3 max-w-[250px]"
                style={{
                  zIndex: 1000,
                }}
              >
                <p className="text-xl text-[#31B5FF] md:text-3xl">
                  {item.title}
                </p>
                <ul className="my-6">
                  {item.des?.map((d, k) => (
                    <li className="text-xs md:text-base">{d}</li>
                  ))}
                </ul>
                <img
                  className={`item.img rounded-full border-8 border-solid border-[#31B5FF] bg-[#180E20] ${
                    !item.img ? "hidden" : "flex"
                  }`}
                  src={item.img}
                  alt=""
                />
              </div>
            ) : (
              <div
                className="col-span-3 mt-[60px] md:mt-0 max-w-[250px]"
                style={{
                  zIndex: 1000,
                }}
              >
                <img
                  className={`item.img rounded-full border-8 border-solid border-[#31B5FF] bg-[#180E20] ${
                    !item.img ? "hidden" : "flex"
                  }`}
                  src={item.img}
                  alt=""
                />
                <p className="my-6 text-xl text-[#31B5FF] md:text-3xl">
                  {item.title}
                </p>
                <ul>
                  {item.des?.map((d, k) => (
                    <li className="text-xs md:text-base">{d}</li>
                  ))}
                </ul>
              </div>
            ),
          )}
          <div
            className="absolute top-[45px] left-[55%] h-[550px] w-1 rotate-[33deg] bg-[#31B5FF] md:-top-[100px] md:left-[42%] md:h-[1020px] md:rotate-[56deg]"
            style={{
              zIndex: 999,
            }}
          />
        </div>
      </div>
          <img className="absolute w-[200px] md:w-[300px] -right-40 top-[55%]" src="/images/ellipse.svg" alt="" style={{
              zIndex: 999,
            }} />
    </div>
  )
}
export default Roadmap
