import { useParams } from 'react-router-dom'
import React from 'react'
import MysteryBox from '../components/Box/MysteryBox'
import Layout from '../components/Layout'
import NftPage from '../components/Nft/NftPage'

function Safe() {
  const { typeBox } = useParams()
  console.log("🚀 ~ file: Safe.tsx ~ line 9 ~ Safe ~ typeBox", typeBox)

  return (
    <Layout>
      <MysteryBox />
    </Layout>
  )
}

export default Safe
