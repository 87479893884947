import { useWeb3React } from '@web3-react/core'
import BigNumber from 'bignumber.js'
import { ethers } from 'ethers'
import { useCallback, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useParams } from 'react-router-dom'

import addresses from '../../constants/contracts'
import { useERC20, useNftContract, useSellNftContract } from '../../hooks/useContract'
import { getAddress } from '../../lib/address-helper'
import { DEFAULT_REF } from '../../lib/constants'
import { mysteryBox } from '../LandingPage/config'
import Layout from '../Layout'
import ModalConnectWallet from '../ModalConnectWallet'
import SelectTokenAndPrice from './SelectTokenAndPrice'

function NftPage(props: any) {
  const { account } = useWeb3React()
  const { typeBox } = useParams()
  const { name, image, des } = mysteryBox[Number(typeBox)]

  const [selectedToken, setSelectedToken] = useState('MATIC')
  const [priceWei, setPriceWei] = useState('0')
  const [amount, setAmount] = useState('1')
  const [isApproved, setIsApproved] = useState(false)
  const [isOpening, setIsOpening] = useState(false)
  const [isOpenBoxSuccess, setIsOpenBoxSuccess] = useState(false)
  const [openedNftId, setOpenedNftId] = useState(null)

  const priceEth = new BigNumber(priceWei).toFixed(4)
  const estTotal = new BigNumber(priceWei).times(amount).toFixed(4)

  const sellNftContract = useSellNftContract()
  const nftContract = useNftContract()
  const usdcAddress = getAddress(addresses.usdc)
  const usdcContract = useERC20(usdcAddress)

  const getData = useCallback(async () => {
    const proceBoxByUSD = await sellNftContract.priceBox(typeBox || 0)
    const rateMaticUsd = await sellNftContract.getBNBPrice()

    switch (selectedToken) {
      case 'MATIC':
        const priceMatic = new BigNumber(proceBoxByUSD.toString()).dividedBy(rateMaticUsd.toString()).times(1e18).dividedBy(1e6)

        setPriceWei(priceMatic.toString())
        setIsApproved(true)
        break
      case 'USDC':
        const priceUsdc = new BigNumber(proceBoxByUSD.toString()).dividedBy(1e6)
        setPriceWei(priceUsdc.toString())

        const allowance = await usdcContract.allowance(account || DEFAULT_REF, sellNftContract.address)
        if (allowance.gt(0)) {
          setIsApproved(true)
        } else {
          setIsApproved(false)
        }
        break

      default:
        break
    }
  }, [account, nftContract, priceWei, selectedToken, sellNftContract, usdcContract, typeBox])

  const handleApprove = async () => {
    try {
      let tx = null as any
      switch (selectedToken) {
        case 'USDC':
          tx = await usdcContract.approve(sellNftContract.address, ethers.constants.MaxUint256)
          break
        default:
          break
      }
      const finish = async () => {
        await tx.wait()
        setIsApproved(true)
      }
      toast.promise(finish(), {
        loading: 'Process approve...',
        success: 'Approved',
        error: 'Approve error'
      })
    } catch (error) {
      console.log(error)
      //@ts-ignore
      toast.error(error?.data?.message || error?.message)
    }
  }

  const handleBuyNft = useCallback(async () => {
    const storageRef = localStorage.getItem('referral') || DEFAULT_REF
    const ref = ethers.utils.isAddress(storageRef) ? storageRef : DEFAULT_REF

    try {
      let tx = null as any
      switch (selectedToken) {
        case 'MATIC':
          tx = await sellNftContract.buyBoxByBNB(typeBox || 0, amount, ref, {
            value: new BigNumber(estTotal).times(1e18).times(1.1).toFixed(0)
          })
          break
        case 'USDC':
          tx = await sellNftContract.buyBoxByUSD(typeBox || 0, usdcAddress, amount, ref, {
            gasLimit: 350000
          })
          break

        default:
          throw new Error('Wrong token')
      }

      setIsOpening(true)
      const finish = async () => {
        const res = await tx.wait()
        setIsOpening(false)
        if (res.status === 0) throw new Error('Tx failed')

        setIsOpenBoxSuccess(true)

        const filter = nftContract.filters.NewNFTMint(account)
        const history = await nftContract.queryFilter(filter, res.blockNumber)
        for (const historyData of history) {
          const { user, tokenID, instance, mintAt } = historyData.args
          //@ts-ignore
          setOpenedNftId(instance.toString())
        }
      }

      toast.promise(finish(), {
        loading: 'Processing...',
        success: 'Success',
        error: () => {
          setIsOpening(false)
          return 'Open box error'
        }
      })
    } catch (error) {
      console.log(error)
      //@ts-ignore
      toast.error(error?.data?.message || error?.message)
    }
  }, [amount, estTotal, nftContract, selectedToken, sellNftContract, typeBox, usdcAddress])

  const handleSelectToken = (token: any) => {
    setSelectedToken(token)
    // getData()
  }

  const handleQuantityChange = (e: any) => {
    if (Number(e.target.value) > 0) {
      setAmount(e.target.value)
    } else {
      setAmount('')
    }
  }

  useEffect(() => {
    if (typeBox) {
      getData()
    }
  }, [sellNftContract, selectedToken, typeBox])

  return (
    <>
      <Layout>
        <div className="bg-[#0A0014] py-20 px-4 md:px-0">
          <div className="mx-auto max-w-5xl">
            <p className="text-5xl font-bold text-[#31B5FF]">Safe</p>

            <div className="mt-20 grid grid-cols-12 md:gap-10 gap-y-5">
              <div className="col-span-12 md:col-span-5">
                <div>
                  <img
                    alt=""
                    src={image}
                    style={{
                      filter: 'drop-shadow(0px 4px 24px rgba(206, 206, 206, 0.25))'
                    }}
                    className="rounded-lg"
                  />
                </div>
              </div>

              <div className="col-span-12 flex md:col-span-7">
                <img className="h-[405px]" src="/images/safe/mystery-border-left.png" alt="" />
                <div
                  className="flex flex-col bg-cover bg-center bg-no-repeat"
                  style={{
                    backgroundImage: `url(/images/safe/mysteryBox-bg.png)`
                  }}>
                  <div className="border-b border-gray-700 p-5">
                    <p className="text-3xl font-bold text-white">Safe</p>
                  </div>

                  <div className="grid grid-cols-2 divide-x divide-gray-700 border-b border-gray-700">
                    <div className="px-8 py-5">
                      <SelectTokenAndPrice onSelectToken={handleSelectToken} price={priceEth} />

                      <div className="mt-4 flex flex-col">
                        <p className="text-xs text-gray-400">Amount</p>
                        <div className="mt-2 flex rounded-md bg-[#131518] py-2 px-4">
                          <input
                            value={amount}
                            onChange={handleQuantityChange}
                            className="w-full bg-transparent text-white focus:outline-none"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="px-8 py-5">
                      <div className="flex flex-col">
                        <p className="text-xs text-gray-400">Est. Total</p>
                        <div className="mt-2 flex items-center">
                          <img src={`/images/tokens/${selectedToken}.svg`} alt="" className="mr-2 w-[24px]" />
                          <p className="text-2xl font-bold text-white">
                            {Number(estTotal) ?? 0} {selectedToken}
                          </p>
                        </div>
                      </div>

                      {account ? (
                        <div className="mt-9">
                          {isApproved ? (
                            <div className="w-fit bg-[#31B5FF] p-1">
                              <button
                                className="border border-solid border-[#0A0014] bg-[#31B5FF] px-8 py-2 font-bold text-black duration-300 hover:scale-105"
                                onClick={handleBuyNft}>
                                OPEN SAFE
                              </button>
                            </div>
                          ) : (
                            <div className="w-fit bg-[#31B5FF] p-1">
                              <button
                                className="border border-solid border-[#0A0014] bg-[#31B5FF] px-8 py-2 font-bold text-black duration-300 hover:scale-105"
                                onClick={handleApprove}>
                                APPROVE
                              </button>
                            </div>
                          )}
                        </div>
                      ) : (
                        <div className="mt-9 w-fit bg-[#31B5FF] p-1">
                          <button
                            className="border border-solid border-[#0A0014] bg-[#31B5FF] px-8 py-2 font-bold text-black duration-300 hover:scale-105"
                            onClick={handleApprove}>
                            <ModalConnectWallet
                              label={account ? `${account.substring(0, 6)}...${account.substring(account.length - 4)}` : 'Connect wallet'}
                            />
                          </button>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="p-8">
                    <p className="text-white">{des}</p>
                  </div>
                </div>
                <img className="h-[405px]" src="/images/safe/mystery-border-right.png" alt="" />
              </div>
            </div>
          </div>
        </div>

        {isOpening && (
          <video
            className="fixed inset-0 z-[49] h-screen w-screen object-fill"
            muted
            // controls={false}
            loop
            autoPlay>
            <source src={`https://storage.googleapis.com/money-nft/assets/Idle.mp4`} type="video/mp4" />
          </video>
        )}
        {isOpenBoxSuccess && (
          <video
            className="fixed inset-0 z-[49] h-screen w-screen object-fill"
            muted
            autoPlay
            onEnded={() => {
              setIsOpenBoxSuccess(false)
            }}>
            <source src={`https://storage.googleapis.com/money-nft/assets/Unbox.mp4`} type="video/mp4" />
          </video>
        )}
        {openedNftId && (
          <div
            className="bg-[gba(26, 30, 36, 0.3)] fixed inset-0 flex items-center justify-center"
            style={{ backdropFilter: 'blur(32px)' }}>
            <div className="flex flex-col">
              <img src={`https://storage.googleapis.com/money-nft/nft/${openedNftId}.jpg`} />
              <button onClick={() => setOpenedNftId(null)} className="mt-10 rounded-md bg-white px-4 py-2 font-bold">
                Claim NFT
              </button>
            </div>
          </div>
        )}
      </Layout>
    </>
  )
}

export default NftPage
