import { useWeb3React } from '@web3-react/core'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { pagination } from '../../lib/pagination'
import PaginationLeft from '../PaginationLeft'
import PaginationRight from '../PaginationRight'


function MyAssetsPage() {
  const { account } = useWeb3React()

  const [nfts, setNfts] = useState([])
  const [count, setCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [perPage, setPerPage] = useState(2)

  const pages = pagination(currentPage, Math.ceil(count / perPage))

  const fetchData = async () => {
    console.log('fetching data')
    const skip = (currentPage - 1) * perPage
    // prettier-ignore
    const url = `${process.env.REACT_APP_MORALIS_URL}/getUserNft?_ApplicationId=${process.env.REACT_APP_MORALIS_APPLICATION_ID}&projectId=${process.env.REACT_APP_MORALIS_PROJECT_ID}`

    const response = await fetch(`${url}&skip=${skip}&limit=${perPage}&ethAddress=${account?.toLowerCase()}`).then((res) => res.json())

    setNfts(response.result?.data)
    setCount(response.result?.count)
  }

  useEffect(() => {
    if (account) {
      fetchData()
    }
  }, [account, currentPage])

  return (
    <div className={`bg-[#0A0014] pb-10 ${nfts.length === 0 && 'min-h-screen'}`}>
      <div className="border-b border-gray-700 py-10">
        <p className="text-center text-5xl font-bold text-white">My assets</p>
      </div>

      <div className="mx-auto mt-12 flex max-w-5xl flex-col">
        {nfts.length === 0 && account && (
          <div className="flex flex-col items-center">
            <p className="text-white">You have no MONEY NFT</p>
            <Link to="/safe">
              <button className="button mt-4 font-bold text-black">Buy NFT</button>
            </Link>
          </div>
        )}

        <div className="grid grid-cols-2 gap-y-6 gap-x-6 md:grid-cols-4">
          {nfts.map((nft : any) => (
            <div className="flex-col">
              <img src={`https://storage.googleapis.com/money-nft/nft/${nft.instance}.jpg`} alt="" />
              <a href="https://opensea.io/" target="_blank">
                <p className="p-8 text-center">Sell on opensea</p>
              </a>
            </div>
          ))}
        </div>

        {nfts.length > 0 && (
          <div className="mt-10 flex items-center justify-center">
            <PaginationLeft onClick={() => setCurrentPage(currentPage - 1)} className="cursor-pointer" />
            {/* @ts-ignore */}
            {pages?.map((i) => {
              return (
                <button
                  className={`mx-2 flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-full bg-gradient-to-t focus:outline-none ${
                    currentPage === i ? 'from-[#04876F]' : 'from-[#F19920]'
                  } ${currentPage === i ? 'to-[#0CF0C7]' : 'to-[#FECC5C]'}`}
                  onClick={() => setCurrentPage(Number(i))}>
                  <p className="text-2xl font-bold text-white" style={{ textShadow: '0px 0px 3px rgba(121, 23, 23, 0.8)' }}>
                    {i}
                  </p>
                </button>
              )
            })}
            <PaginationRight onClick={() => setCurrentPage(currentPage + 1)} className="cursor-pointer" />
          </div>
        )}
      </div>
    </div>
  )
}

export default MyAssetsPage
