import Layout from "../components/Layout"
import MyAssetsPage from "../components/MyAssets/MyAssetsPage"

function MyAssets() {
  return (
    <Layout>
      <MyAssetsPage />
    </Layout>
  )
}

export default MyAssets
