const config = [
  {
    name: "James Harry",
    role: "Founder",
    img: "/images/team/founder.jpeg",
  },
  {
    name: "Michelle Dorothy",
    role: "Project Managerment",
    img: "/images/team/pm.jpeg",
  },
  {
    name: "Jonathan Kenedy",
    role: "Frontend Developer",
    img: "/images/team/dev.jpeg",
  },
  {
    name: "Pamela Rachel",
    role: "Business Analyst",
    img: "/images/team/ba.jpeg",
  },
]

function Team() {
  return (
    <div className="mt-40 mb-52">
      <div className="mx-auto flex max-w-6xl flex-col justify-center px-4 md:px-0">
        <p className="text-center text-4xl font-bold">
          Meet our development team
        </p>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-6 mt-20">
          {config.map((item, index) => (
            <div className="relative flex flex-col items-center justify-end bg-transparent">
              <div
                className="absolute top-0 left-0 h-full w-full bg-[#FFFFFF] opacity-20"
                style={{
                  zIndex: 999,
                }}
              />
              <div className="flex-col justify-end flex" style={{
                zIndex: 1000,
              }}>
                <img src={item.img} alt="" className="w-fit" />
                <div className="w-full bg-[#0B051A] py-4">
                  <p className=" text-center text-xl font-bold">{item.name}</p>
                  <p className="text-center">{item.role}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Team
