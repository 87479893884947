export const mysteryBox = [
  // {
  //   name: "Basic Safe",
  //   img: "/images/safe/basic-safe.png",
  // },
  {
    image: "/images/safe/box1.png",
    name: "Black Safe",
    img: "/images/safe/black-safe.png",
    des: "With the highest security, Black safe is covering the big secret! Unlock the safe, the treasure will belong to you!",
  },
  {
    image: "/images/safe/box3.png",
    name: "Premium Safe",
    img: "/images/safe/premium-safe.png",
    des: "The premium safe is encrypted, you are the only one holding secret characters that can open it! Utilise your power and take your worthy prize.",
  },
  {
    image: "/images/safe/box3.png",
    name: "Standard Safe",
    img: "/images/safe/standard-safe.png",
    des: "Just open it & take all the money in this big safe! Easy, peasy! Let's do it!",
  },
]

export const roadmapData = [
  {},
  {},
  {
    title: "Q1-2023",
    des: [
      "- Use Stable NFT for paying deal with stategy partners",
      "- Expand the approval for Stable NFT of cooperated ventures",
    ],
    img: "/images/roadmap/q1.png",
  },
  {
    title: "Q2-2023",
    des: ["- Listing on NFT market of Binance", "- Build the charity fund"],
    img: "/images/roadmap/q2.png",
  },
  {
    title: "Q3-2022",
    des: [
      "- Airdrop Campaign",
      "- Build community with 200,000 members",
      "- Mystery box sale event",
    ],
    img: "/images/roadmap/q3.png",
  },
  {
    title: "Q4-2022",
    des: [
      "- Listing NFT on Opensea",
      "- Cooperate with ventures",
      "- Listing on Coinmarketcap",
    ],
    img: "/images/roadmap/q4.png",
  },
  // {
  //   title: "Q1-2023",
  //   des: [
  //     "Use Stable NFT for paying deal with stategy partners",
  //     "Expand the approval for Stable NFT of cooperated ventures",
  //   ],
  //   img: '/images/roadmap/q1.png',
  // },
  // {
  //   title: "Q2-2022",
  //   des: ["Listing on NFT market of Binance", "Build the charity fund"],
  //   img: '/images/roadmap/q2.png',
  // },
]
