// import dynamic from "next/dynamic"
import Collect from "./Collect"
import Denomination from "./Denmomination"
import Dive from "./Dive"
// import Carousel from "./Carousel"
import Hero from "./Hero"
import Roadmap from "./Roadmap"
import Team from "./Team"

// const Carousel = dynamic(() => import("./Carousel"), {
//   ssr: false,
// })

function index() {
  return (
    <div className="bg-[#0a0014]">
      <Hero />
      <Dive />
      <Denomination />
      <Collect />
      <Team />
      <Roadmap />
    </div>
  )
}

export default index
