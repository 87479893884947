import { Link } from "react-router-dom"
import styled from "styled-components"
import { mysteryBox } from "../LandingPage/config"

const config = [
  // {
  //   name: "Basic Safe",
  //   img: "/images/safe/basic-safe.png",
  // },
  {
    name: "Standard Safe",
    img: "/images/safe/standard-safe.png",
  },
  {
    name: "Premium Safe",
    img: "/images/safe/premium-safe.png",
  },
  {
    name: "Black Safe",
    img: "/images/safe/black-safe.png",
  },
]

const Hover = styled.div`
  cursor: pointer;
  box-shadow: 0px 8px 18px rgba(120, 120, 120, 0.25);

  &:hover {
    #div-bottom1 {
      display: hidden;
      --tw-translate-y: -100px;
      transform: translate(var(--tw-translate-x), var(--tw-translate-y))
        rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
        scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    }

    #div-bottom {
      --tw-translate-y: -100px;
      transform: translate(var(--tw-translate-x), var(--tw-translate-y))
        rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
        scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    }
  }
`

function Box() {
  return (
    <div className="min-h-screen bg-[#0A0014]">
      <div className="mx-auto max-w-6xl pt-24 px-4 md:px-0">
        <p className="text-6xl font-bold text-[#31B5FF] underline decoration-1">
          Safe
        </p>
        <div className="flex gap-6 flex-col md:flex-row mt-32">
          {mysteryBox.map((item, index) => (
            <Hover className="flex flex-col justify-start bg-[#0B051A]">
              <img src={item.img} alt="" className="w-fit" />
              <div className="flex h-[80px] flex-col overflow-hidden">
                <div
                  id="div-bottom1"
                  className="flex justify-center duration-300 ease-in-out"
                >
                  <p className="py-8 text-center text-xl font-bold">
                    {item.name}
                  </p>
                </div>
                <div
                  id="div-bottom"
                  className="flex items-center justify-between px-6 duration-300 ease-in-out"
                >
                  <p className="py-8 text-center text-xl font-bold">
                    {item.name}
                  </p>
                  <div id="button" className="h-fit w-fit bg-[#31B5FF] p-1">
                    <Link to={`/safe/${index}`}>
                      <button className="border border-solid border-[#0A0014] bg-[#31B5FF] px-8 py-1 font-bold text-black duration-300 hover:scale-105">
                        BUY SAFE
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </Hover>
          ))}
        </div>
      </div>
    </div>
  )
}
export default Box
