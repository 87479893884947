import React from "react"
import { Link } from "react-router-dom"

function Hero() {
  return (
    <div className="relative py-24">
      <div className="mx-auto grid max-w-7xl gap-10 px-4 md:grid-cols-2 md:gap-0 md:px-0">
        <div className="">
          <img src="/images/landingpage/hero-img.png" alt="hero-img" />
        </div>
        <div className="flex flex-col justify-center self-center">
          <p className="text-5xl font-bold md:text-7xl">STABLE NFTS</p>
          <div className="mr-32 mt-6 mb-28">
            <p className="text-2xl md:text-4xl">
              The perfect combinantion between NFT & stablecoin
            </p>
            <p className="my-11 md:text-xl">
              Stable coins?!!! Well, nope! It's stable-NFTs! Every image should
              be stable to the cryptocurrency that it shows. Purchase 1 NFT by
              $100, will you keep it as a $100 ratio
            </p>
            <div className="flex gap-6">
              <img
                className="mb-5"
                src="/images/landingpage/arow-right.svg"
                alt=""
              />
              <p className="md:text-xl">
                Stable NFT - Mix of the stable of fiat and the unique of NFT
              </p>
            </div>
            <div className="mt-4 flex gap-6">
              <img
                className="mb-5"
                src="/images/landingpage/arow-right.svg"
                alt=""
              />
              <p className="md:text-xl">
                Stable NFT - New trendy payment instrument in the future
              </p>
            </div>
          </div>
          <div className="w-fit bg-[#31B5FF] p-1">
            <Link to="/safe">
              <button className="border border-solid border-[#0A0014] bg-[#31B5FF] px-16 py-3 font-bold text-black duration-300 hover:scale-105">
                EXPLOSE
              </button>
            </Link>
          </div>
        </div>
      </div>
      <img
        className="absolute -right-40 top-[55%] md:bottom-0 w-[200px] md:w-[300px]"
        src="/images/ellipse.svg"
        alt=""
        style={{
          zIndex: 999,
        }}
      />
    </div>
  )
}

export default Hero
