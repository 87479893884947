import{ Outlet, Link, useMatch, useLocation } from "react-router-dom";
// import Link from "next/link"
// import { useRouter } from "next/router"
import { useWeb3React } from "@web3-react/core"
import { Web3Provider } from "@ethersproject/providers"
import ModalConnectWallet from "./ModalConnectWallet"
// import MobileNav from "./MobileNav"

function MainNav() {
  const { account } = useWeb3React()

  return (
    <div className="relative flex items-center justify-start bg-[#04060A] px-2 md:px-4">
      <div className="mx-auto flex w-full max-w-6xl items-center justify-between">
        <div className="mr-8 flex items-center rounded-full">
          <Link to="/">
            <div className="cursor-pointer py-4 md:py-0">
              <img src="/logo.svg" width="150px" />
            </div>
          </Link>
        </div>

        <div className="items-center md:flex">
          <Item href="/">Home</Item>
          <Item href="/safe">Safe</Item>
          <Item href="/my-assets">My Assets</Item>

          <div className="my-4 w-fit bg-[#31B5FF] p-1">
            <button className="border border-solid border-[#0A0014] bg-[#31B5FF] font-bold text-black duration-300 hover:scale-105">
              <div>
                <ModalConnectWallet
                  label={
                    account
                      ? `${account.substring(0, 6)}...${account.substring(
                          account.length - 4,
                        )}`
                      : "Connect"
                  }
                />
              </div>
            </button>
          </div>
        </div>
      </div>

      {/* <div className="flex items-center md:hidden">
        <div className="py-6 ml-3 md:hidden">
          <MobileNav />
        </div>
      </div> */}
    </div>
  )
}

export default MainNav

function Item({ children, href, ...props }: { children: React.ReactNode; href: string }) {
const { pathname } = useLocation()
  const isActive = pathname === href

  return (
    <Link to={href || "/"}>
      <div
        className={`${!isActive ? "border-b-0" : "border-b-2"} mx-3 flex py-4`}
      >
        <p
          className={`${
            !isActive ? "text-[#828282]" : "font-bold text-white"
          } cursor-pointer uppercase`}
        >
          {children}
        </p>
      </div>
    </Link>
  )
}
