const config = [
  {
    title: "$3 Billion",
    des: "Total NFT market value at current time.",
  },
  {
    title: "704%",
    des: "NFT trading volume rose between Q2 2021 and Q3 2021",
  },
  {
    title: "250,000",
    des: "Users trade NFTs on the OpenSea per month",
  },
]

function Dive() {
  return (
    <div className="">
      <div
        className="mx-auto flex max-w-6xl justify-between bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: `url("/images/landingpage/dive-bg.png")`,
        }}
      >
        <img
          className="md:hidden"
          src="/images/landingpage/dive-border-left-444.png"
          alt=""
        />
        <img
          className="hidden md:flex"
          src="/images/landingpage/dive-border-left.svg"
          alt=""
        />
        <div className="my-6 flex flex-col gap-3 self-center px-7 md:my-0 md:flex-row md:px-12">
          <p className="text-3xl">Dive into the thrilling NFT marketplace</p>
          {config.map((item, index) => (
            <div className="flex flex-col">
              <p className="text-3xl text-[#31B5FF]">{item.title}</p>
              <p>{item.des}</p>
            </div>
          ))}
        </div>
        <img
          className="md:hidden"
          src="/images/landingpage/dive-border-right-444.png"
          alt=""
        />
        <img
          className="hidden md:flex"
          src="/images/landingpage/dive-border-right.svg"
          alt=""
        />
      </div>
    </div>
  )
}

export default Dive
