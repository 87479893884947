export const addresses = {
  multiCall: {
    56: "0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B",
    97: "0x8F3273Fb89B075b1645095ABaC6ed17B2d4Bc576",
  },
  ido: {
    56: "0x22183740Ca93a19ef8ac19DbA20B6469C18d12f2",
    97: "0x8f8c8b8b8f8c8b8b8f8c8b8b8f8c8b8f8c8b8f8c",
  },
  airdrop: {
    56: "0x0000000000000000000000000000000000000000",
    97: "0x0000000000000000000000000000000000000000",
  },
  busd: {
    56: "0x0000000000000000000000000000000000000000",
    97: "0x0000000000000000000000000000000000000000",
    80001: "0x2Cfc413a263c508600dE580769f400163c08400B",
    137: "0x0000000000000000000000000000000000000000",
  },
  usdt: {
    56: "0x0000000000000000000000000000000000000000",
    97: "0x0000000000000000000000000000000000000000",
    80001: "0x3167CD37Dc8FB9BBc87E1F45fBeC6DF9aD699DC8",
    137: "0x0000000000000000000000000000000000000000",
  },
  usdc: {
    56: "0x0000000000000000000000000000000000000000",
    97: "0x0000000000000000000000000000000000000000",
    80001: "0x33FA450AB9B88baC238252a71580C10d025236E6",
    137: "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
  },
  wweNFT: {
    56: "0x0000000000000000000000000000000000000000",
    97: "0x3C117a2F42B177885b4a5A977B5fD1bC7Aa73754",
    80001: "0x6ea4D9476EC46418F89ed1A76865a61Cc04aA756",
    137: "0x7Ed36dd7C0eA8938E535fA16e0004cF00715BF85",
  },
  wweSellNFT: {
    56: "0x0000000000000000000000000000000000000000",
    97: "0xd30d112a8ee6D14D4d199577287A339DBF877C8d",
    80001: "0x0fd387B67311B4b5CdA0b7b50822C237321C4E50",
    137: "0x781D7067a79bfa1F32bAAF25f3B98c31C46f827D",
  },
  wweNFTMarket: {
    56: "0x0000000000000000000000000000000000000000",
    97: "0x6ea4D9476EC46418F89ed1A76865a61Cc04aA756",
    80001: "0xd30d112a8ee6D14D4d199577287A339DBF877C8d",
    137: "0x81ab1D57110dE9964fAE917D55cC6e4165e1c662",
  },
  nft: {
    56: "0x0000000000000000000000000000000000000000",
    97: "0x0000000000000000000000000000000000000000",
    80001: "0x5a06f5657839045179eA070e9F9b3e3A8cFeB88B",
    137: "0x3f3cd3c2E9CD2818565c60764F4d5493De2C564c",
  },
  sellNft: {
    56: "0x0000000000000000000000000000000000000000",
    97: "0x0000000000000000000000000000000000000000",
    80001: "0xb6F444FafBC8Edc920c20d3FAd1Ef09A0D6F0B9b",
    137: "0xd8789A26246e99a3027c3b41171445f660C06085",
  },
}

export default addresses
