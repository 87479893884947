import { motion } from "framer-motion"
import { useEffect, useRef, useState } from "react"

const subMenuAnimate = {
  enter: {
    opacity: 1,
    rotateX: 0,
    transition: {
      duration: 0.5,
    },
    display: "block",
  },
  exit: {
    opacity: 0,
    rotateX: -15,
    transition: {
      duration: 0.5,
      delay: 0.1,
    },
    transitionEnd: {
      display: "none",
    },
  },
}

function SelectTokenAndPrice({ onSelectToken, price, ...BigNumberprops }: any) {
  const node = useRef() as any
  const [isOpen, toggleOpen] = useState(false)
  const toggleOpenMenu = () => {
    toggleOpen(!isOpen)
  }

  const handleClickOutside = (e:any) => {
    // @ts-ignore
    if (node.current?.contains(e.target)) {
      return
    }
    toggleOpen(false)
  }

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside)
    } else {
      document.removeEventListener("mousedown", handleClickOutside)
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [isOpen])

  const [selectedToken, setSelectedToken] = useState("MATIC")

  const handleSelectToken = (token: string) => {
    setSelectedToken(token)
    onSelectToken(token)
    toggleOpen(false)
  }

  return (
    <div ref={node} className="relative">
      <div className="flex flex-col">
        <p className="text-xs text-gray-400">Price</p>
        <div
          className="mt-2 flex cursor-pointer items-center"
          onClick={toggleOpenMenu}
        >
          <img
            src={`/images/tokens/${selectedToken}.svg`}
            alt=""
            className="mr-2 w-[24px]"
          />
          <p className="text-2xl font-bold text-white">
            {price} {selectedToken}
          </p>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="ml-1 h-5 w-5 text-white"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19 9l-7 7-7-7"
            />
          </svg>
        </div>
      </div>

      <motion.div
        initial="exit"
        animate={isOpen ? "enter" : "exit"}
        variants={subMenuAnimate}
        className="absolute top-14 right-0 left-0 z-20"
        style={{
          borderRadius: 5,
          transformOrigin: "50% -30px",
          // boxShadow: "inset 0px -2px 4px #02253F",
          backgroundColor: "#131518",
          // width: 120,
          paddingTop: 12,
          paddingBottom: 12,
        }}
      >
        <div className="flex flex-col">
          <Item
            onClick={() => handleSelectToken("MATIC")}
            tokenSymbol="MATIC"
          />
          <Item onClick={() => handleSelectToken("USDC")} tokenSymbol="USDC" />
        </div>
      </motion.div>
    </div>
  )
}

export default SelectTokenAndPrice

function Item({ tokenSymbol, ...props }: any) {
  return (
    <div className="flex cursor-pointer items-center py-2 px-4" {...props}>
      <img
        src={`/images/tokens/${tokenSymbol}.svg`}
        style={{ width: 24, height: 24 }}
      />
      <p className="ml-2 font-bold text-white">{tokenSymbol}</p>
    </div>
  )
}
