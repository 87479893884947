import { useCallback, useEffect, useState } from 'react'
import { getAddChainParameters } from '../../chains';
import { hooks, metaMask } from '../../connectors/metaMask'
import { Card } from '../Card'

const { useChainId, useAccounts, useIsActivating, useIsActive, useProvider, useENSNames } = hooks

export default function MetaMaskCard({ icon, lable, close }: { icon: string; lable: string; close: () => void }) {
  // const chainId = useChainId()
  // const accounts = useAccounts()
  // const isActivating = useIsActivating()

  // const isActive = useIsActive()

  const provider = useProvider()
  const ENSNames = useENSNames(provider)

  const [error, setError] = useState(undefined)

  // attempt to connect eagerly on mount
  // useEffect(() => {
  //   void metaMask.connectEagerly().catch(() => {
  //     console.debug('Failed to connect eagerly to metamask')
  //   })
  // }, [])

  const handleClick = useCallback((): void => {
    setError(undefined)
    metaMask
      .activate(getAddChainParameters(137))
      .then(() => {
        localStorage.setItem('connectedKey', 'connected')
        setError(undefined)
        close()
      })
      .catch(setError)
  }, [metaMask, setError])

  return (
    <div className="mb-2 flex cursor-pointer rounded-lg border border-solid border-gray-400 px-4 py-2 duration-300 ease-in-out hover:border-gray-200 active:translate-y-1">
      <div className="flex gap-6" onClick={handleClick}>
        <img className="h-6 w-6" src={icon} alt="" />
        <p className="text-xl font-bold">{lable}</p>
      </div>
    </div>
  )
}
