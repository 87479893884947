const config = [
  // {
  //   name: "Basic Safe",
  //   img: "/images/safe/basic-safe.png",
  // },
  {
    name: "Standard Safe",
    img: "/images/safe/standard-safe.png",
  },
  {
    name: "Premium Safe",
    img: "/images/safe/premium-safe.png",
  },
  {
    name: "Black Safe",
    img: "/images/safe/black-safe.png",
  },
]

function Collect() {
  return (
    <div className="">
      <div className="mx-auto flex max-w-6xl flex-col justify-center px-4 md:px-0">
        <p className="text-center text-4xl font-bold">
          Collect rare safes, gain valuable NFT
        </p>
        <p className="my-9 mx-auto max-w-xl text-center">
          The collectial safes has 3 types, including: Black Safe, Premium Safe
          and Standard Safe. The greater the safe rarity reaches, the higher
          value of currency you gain!
        </p>
        <div className="grid grid-cols-3 md:gap-6 gap-3 w-full">
          {config.map((item, index) => (
            <div className="flex flex-col items-center justify-start bg-[#0B051A]">
              <img src={item.img} alt="" className="w-fit" />
              <p className="py-8 text-center text-xl font-bold">{item.name}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Collect
